<template>
  <div
    :style="{ height: clientHeight - 180 + 'px' }"
    style="overflow-y: scroll"
  >
    <div class="top">
      <div class="top-select">
        <el-form :model="SerachInput" label-width="100px">
          <el-form-item label="添加时间:">
            <el-col>
              <el-date-picker
                v-model="SerachInput.time"
                type="datetimerange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                format="yyyy-MM-dd HH:mm:ss"
                value-format="yyyy-MM-dd HH:mm:ss"
              ></el-date-picker>
            </el-col>
          </el-form-item>
          <el-form-item label="使用时间:">
            <el-col>
              <el-date-picker
                v-model="SerachInput.times"
                type="datetimerange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                format="yyyy-MM-dd HH:mm:ss"
                value-format="yyyy-MM-dd HH:mm:ss"
              ></el-date-picker>
            </el-col>
          </el-form-item>
          <el-form-item label="活动名称:">
            <el-select
              v-model="SerachInput.activityName"
              clearable
              placeholder="请选择"
            >
              <el-option
                v-for="item in activityNames"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="当前状态:">
            <el-select
              v-model="SerachInput.currentState"
              clearable
              placeholder="请选择"
            >
              <el-option
                v-for="item in currentStates"
                :key="item.code"
                :label="item.name"
                :value="item.code"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="兑换码序列号:">
            <el-input
              v-model="SerachInput.serialNumber"
              placeholder="请输入内容"
              style="width: 150px"
            ></el-input>
          </el-form-item>
          <el-form-item label="兑换码密钥:">
            <el-input
              v-model="SerachInput.key"
              placeholder="请输入内容"
              style="width: 150px"
            ></el-input>
          </el-form-item>
          <el-form-item label="用户手机号码:">
            <el-input
              v-model="SerachInput.phone"
              placeholder="请输入内容"
              style="width: 150px"
              @keyup.enter.native="handleQuery"
            ></el-input>
          </el-form-item>
          <el-form-item label="IMEI:" label-width="60px">
            <el-input
              v-model="SerachInput.imei"
              placeholder="请输入内容"
              style="width: 150px"
            ></el-input>
          </el-form-item>
          <el-form-item label-width="30px">
            <img
              src="../../assets/images/query.png"
              alt=""
              style="width: 49px; margin-left: 5%"
              @click="handleQuery"
            />
          </el-form-item>
        </el-form>
      </div>
      <div class="table-content">
        <div style="float: right; margin-bottom: 8px">
          <el-button
            @click="batchRelease"
            style="padding: 9px 25px; color: #909399"
            >批量发布</el-button
          >
          <el-button
            @click="batchTovoid"
            style="padding: 9px 25px; color: #909399"
            >批量作废</el-button
          >
        </div>
        <el-table
          :data="tableData"
          tooltip-effect="dark"
          ref="multipleTable"
          style="width: 100%"
        >
          <!-- <el-table-column type="selection" width="55"> </el-table-column> -->
          <el-table-column prop="cardNo" label="卡号" width="150">
          </el-table-column>
          <el-table-column prop="code" label="密匙" width="175">
          </el-table-column>
          <el-table-column prop="createDate" label="添加时间" width="170">
          </el-table-column>
          <el-table-column prop="activityName" label="活动名称" width="140">
          </el-table-column>
          <el-table-column prop="specificFunction" label="具体功能" width="110">
            <template slot-scope="scope">
              <el-button
                @click="Toview(scope.row.serviceList)"
                style="padding: 6px 16px; font-weight: 400"
                >查看</el-button
              >
            </template>
          </el-table-column>
          <el-table-column prop="endDate" label="使用有效期" width="170">
          </el-table-column>
          <el-table-column prop="userPhone" label="用户手机号码" width="150">
            <template slot-scope="scope">
              <span v-if="!scope.row.userPhone">-</span>
              <span v-else>{{ scope.row.userPhone }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="imei" label="IMEI" width="170">
          </el-table-column>
          <el-table-column prop="userName" label="用户昵称">
            <template slot-scope="scope">
              <span v-if="!scope.row.userName">-</span>
              <span v-else>{{ scope.row.userName }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="exchangeTime" label="使用时间" width="170">
            <template slot-scope="scope">
              <span v-if="!scope.row.exchangeTime">-</span>
              <span v-else>{{ scope.row.exchangeTime }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="statusCn" label="当前状态"> </el-table-column>
          <el-table-column prop="operation" label="操作" width="110">
            <template slot-scope="scope">
              <el-button
                size="small"
                @click="btnStatus(scope.row)"
                style="cursor: pointer"
              >
                <span>作废</span>
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="bottom">
        <div class="bottom-left">
          <p class="bottom-shu">查询兑换码总数{{ totalCount }}</p>
        </div>
        <div class="bottom-right">
          <div class="bottom-fen">
            <!-- 分页 -->
            <div class="pages">
              <el-pagination
                @current-change="handleCurrentChange"
                :page-size="pageSize"
                :current-page="pageCount"
                layout="total, prev, pager, next"
                :total="totalCount"
                @pagination="getEquipment"
              >
              </el-pagination>
              <!-- <el-pagination layout="prev, pager, next" :total="50">
              </el-pagination> -->
            </div>
          </div>
          <div class="buttons">
            <!-- <el-button>数据分析</el-button> -->
            <el-button @click="exportList">数据导出</el-button>
          </div>
        </div>
      </div>
    </div>
    <!-- 增加的弹框 -->
    <div>
      <el-dialog
        :visible.sync="dialogVisible"
        width="30%"
        :before-close="handleClose"
      >
        <div
          style="
            width: 92%;
            margin-left: 4%;
            line-height: 32px;
            color: #909399;
            font-weight: 500;
            padding-bottom: 15px;
          "
        >
          <div v-for="(item, i) in functionQuery" :key="i">
            <p>{{ item }}</p>
          </div>
        </div>
      </el-dialog>
    </div>
    <div>
      <el-dialog
        title="批量发布"
        :visible.sync="allVisible"
        width="30%"
        append-to-body
      >
        <el-upload
          ref="upload"
          accept=".xls,.xlsx"
          action="#"
          :auto-upload="false"
          :multiple="false"
          :file-list="fileList"
          :before-upload="beforeUpload"
          :http-request="uploadHttpRequest"
          :on-remove="fileRemove"
          :on-change="fileChange"
          :headers="myHeaders"
          style="width: 90%; margin-left: 5%"
        >
          <el-button size="small" style="padding: 8px 11px">上传文件</el-button>
          <div slot="tip" class="el-upload__tip">
            一次只能上传一个xls/xlsx文件，且不超过1M
          </div>
        </el-upload>
        <span slot="footer" class="dialog-footer">
          <el-button @click="allVisible = false">取 消</el-button>
          <el-button type="primary" @click="uploadAll">确 定</el-button>
        </span>
      </el-dialog>
    </div>
    <div>
      <el-dialog
        title="批量作废"
        :visible.sync="allVisibles"
        width="30%"
        append-to-body
      >
        <el-upload
          ref="uploads"
          accept=".xls,.xlsx"
          action="#"
          :auto-upload="false"
          :multiple="false"
          :file-list="fileList"
          :before-upload="beforeUpload"
          :http-request="uploadHttpRequests"
          :on-remove="fileRemove"
          :on-change="fileChange"
          :headers="myHeaders"
          style="width: 90%; margin-left: 5%"
        >
          <el-button size="small" style="padding: 8px 11px">上传文件</el-button>
          <div slot="tip" class="el-upload__tip">
            一次只能上传一个xls/xlsx文件，且不超过1M
          </div>
        </el-upload>
        <span slot="footer" class="dialog-footer">
          <el-button @click="allVisibles = false">取 消</el-button>
          <el-button type="primary" @click="uploadAlls">确 定</el-button>
        </span>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import { api } from "../../api1/config";
import { apiShout } from "../../api1/configShout";
export default {
  data() {
    return {
      clientHeight: document.body.clientHeight,
      //增加的弹框
      dialogVisible: false,
      allVisible: false,
      allVisibles: false,
      fileList: [],
      myHeaders: { "Content-Type": "multipart/form-data" },
      SerachInput: {
        time: [],
        times: [],
        type: "",
        phone: "",
        activityName: "",
        currentState: "",
        serialNumber: "",
        key: "",
      },
      activityNames: [],
      currentStates: [],
      input: "",
      tableData: [],
      //分页
      currentPage: 1,
      pageSize: 10,
      totalCount: 0,
      pageCount: 1, //网络请求的页码
      unitNamePage: 0,
      functionQuery: [],
    };
  },
  created() {
    this.getEquipment();
  },
  mounted() {
    const that = this;
    window.onresize = () => {
      return (() => {
        window.screenHeight = document.body.clientHeight;
        that.clientHeight = window.screenHeight;
      })();
    };
    api.getSelectProduct({ dictCode: "exchangecodestatus" }).then((res) => {
      console.log("当前状态", res.data);
      if (res.data.code == 200) {
        this.currentStates = res.data.result;
      }
    });
    apiShout.getActivityDict().then((res) => {
      if (res.data.code == 200) {
        this.activityNames = res.data.result;
      }
    });
  },
  watch: {
    clientHeight(val) {
      // 为了避免频繁触发resize函数导致页面卡顿，使用定时器
      if (!this.timer) {
        // 一旦监听到的screenWidth值改变，就将其重新赋给data里的screenWidth
        this.clientHeight = val;
        this.timer = true;
        let that = this;
        setTimeout(function () {
          // 打印screenWidth变化的值
          console.log(that.clientHeight);
          that.timer = false;
        }, 400);
      }
    },
  },
  methods: {
    //数据导出
    exportList() {
      let that = this;
      that
        .$http({
          url: "/too/center/wechat/exportExchangeCodeList",
          params: {
            cardNo: this.SerachInput.serialNumber,
            code: this.SerachInput.key,
            status: this.SerachInput.currentState,
            createStartDate:
              this.SerachInput.time == null ? "" : this.SerachInput.time[0],
            createEndDate:
              this.SerachInput.time == null ? "" : this.SerachInput.time[1],
            exchangeStrartTime:
              this.SerachInput.times == null ? "" : this.SerachInput.times[0],
            exchangeEndTime:
              this.SerachInput.times == null ? "" : this.SerachInput.times[1],
            activityId: this.SerachInput.activityName,
            phone: this.SerachInput.phone,
          },
          method: "get",
          responseType: "blob",
          headers: {
            token: JSON.parse(localStorage.getItem("token")),
          },
        })
        .then((res) => {
          // console.log("导出400记录：", res.data);
          const blob = new Blob([res.data], {
            type: "application/vnd.ms-excel",
            crossOrigin: "Anonymous",
          });
          const a = document.createElement("a");
          let date = new Date();
          var year = date.getFullYear();
          var month =
            date.getMonth() + 1 < 10
              ? "0" + (date.getMonth() + 1)
              : date.getMonth() + 1;
          var day = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
          var hours =
            date.getHours() < 10 ? "0" + date.getHours() : date.getHours();
          var minutes =
            date.getMinutes() < 10
              ? "0" + date.getMinutes()
              : date.getMinutes();
          var seconds =
            date.getSeconds() < 10
              ? "0" + date.getSeconds()
              : date.getSeconds();
          // 拼接
          let ds =
            year +
            "-" +
            month +
            "-" +
            day +
            " " +
            hours +
            ":" +
            minutes +
            ":" +
            seconds;
          document.body.appendChild(a);
          a.style.display = "none";
          // 使用获取到的blob对象创建的url
          const url = window.URL.createObjectURL(blob);
          a.href = url;
          // 指定下载的文件名
          a.download = "兑换码" + ds + ".xls";
          a.click();
          document.body.removeChild(a);
          // 移除blob对象的url
          window.URL.revokeObjectURL(url);
        });
    },
    batchRelease() {
      this.allVisible = true;
    },
    batchTovoid() {
      this.allVisibles = true;
    },
    // 文件发生改变
    fileChange(file, fileList) {
      if (fileList.length > 0) {
        this.fileList = [fileList[fileList.length - 1]]; // 展示最后一次选择的文件
        fileList.push(file);
      }
    },
    // 移除选择的文件
    fileRemove(file, fileList) {
      if (fileList.length < 1) {
        this.uploadDisabled = true; //未选择文件则禁用上传按钮
      }
    },
    beforeUpload(file) {
      this.files = file;
      this.fileName = file.name;
      //文件类型
      const isType = file.type === "application/vnd.ms-excel";
      const isTypeComputer =
        file.type ===
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
      const fileType = isType || isTypeComputer;
      if (!fileType) {
        this.$message.error("上传文件只能是xls/xlsx格式！");
      }

      // 文件大小限制为10M
      const fileLimit = file.size / 1024 / 1024 < 1;
      if (!fileLimit) {
        this.$message.error("上传文件大小不超过1M！");
      }
      return fileType && fileLimit;
    },
    //批量发布
    uploadHttpRequest(param) {
      console.log("aaaa:", param);
      const formData = new FormData(); //FormData对象，添加参数只能通过append('key', value)的形式添加
      formData.append("fileName", param.file); //添加文件对象
      // formData.append("fileName", param.file.name);
      console.log("565656:", formData.getAll("file"));
      // const url = '/too/center/equipment/batchImportEquipment'; //上传地址
      let that = this;
      that
        .$http({
          url: "/too/center/wechat/importRelease",
          data: formData,
          method: "POST",
          headers: {
            token: JSON.parse(localStorage.getItem("token")),
          },
        })
        .then((res) => {
          console.log("批量发布：", res.data);
          if (res.data.code == 200) {
            if (res.data.result.errSize > 0) {
              this.$message.error(
                `当前有${res.data.result.errSize}条数据发布失败！`
              );
              console.log("走下载接口");
              that
                .$http({
                  url: "/too/center/wechat/exportDealErrorList",
                  params: {
                    batchNo: res.data.result.batchNo,
                  },
                  method: "get",
                  responseType: "blob",
                  headers: {
                    token: JSON.parse(localStorage.getItem("token")),
                  },
                })
                .then((res) => {
                  console.log("发布失败后再次下载下来的文件", res.data);
                  const blob = new Blob([res.data], {
                    type: "application/vnd.ms-excel",
                    crossOrigin: "Anonymous",
                  });
                  const a = document.createElement("a");
                  let date = new Date();
                  var year = date.getFullYear();
                  var month =
                    date.getMonth() + 1 < 10
                      ? "0" + (date.getMonth() + 1)
                      : date.getMonth() + 1;
                  var day =
                    date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
                  var hours =
                    date.getHours() < 10
                      ? "0" + date.getHours()
                      : date.getHours();
                  var minutes =
                    date.getMinutes() < 10
                      ? "0" + date.getMinutes()
                      : date.getMinutes();
                  var seconds =
                    date.getSeconds() < 10
                      ? "0" + date.getSeconds()
                      : date.getSeconds();
                  // 拼接
                  let ds =
                    year +
                    "-" +
                    month +
                    "-" +
                    day +
                    " " +
                    hours +
                    ":" +
                    minutes +
                    ":" +
                    seconds;
                  document.body.appendChild(a);
                  a.style.display = "none";
                  // 使用获取到的blob对象创建的url
                  const url = window.URL.createObjectURL(blob);
                  a.href = url;
                  // 指定下载的文件名
                  a.download = "兑换码批量发布失败数据" + ds + ".xls";
                  a.click();
                  document.body.removeChild(a);
                  // 移除blob对象的url
                  window.URL.revokeObjectURL(url);
                });
            } else {
              this.$message({
                type: "success",
                message: "已全部发布成功!",
              });
            }
            this.getEquipment();
          } else {
            this.$message.error(res.data.msg);
          }
        });
    },
    //批量作废
    uploadHttpRequests(param) {
      console.log("aaaa:", param);
      const formData = new FormData(); //FormData对象，添加参数只能通过append('key', value)的形式添加
      formData.append("fileName", param.file); //添加文件对象
      // formData.append("fileName", param.file.name);
      console.log("565656:", formData.getAll("file"));
      // const url = '/too/center/equipment/batchImportEquipment'; //上传地址
      let that = this;
      that
        .$http({
          url: "/too/center/wechat/importCancel",
          data: formData,
          method: "POST",
          headers: {
            token: JSON.parse(localStorage.getItem("token")),
          },
        })
        .then((res) => {
          console.log("批量作废：", res.data);
          if (res.data.code == 200) {
            if (res.data.result.errSize > 0) {
              this.$message.error(
                `当前有${res.data.result.errSize}条数据作废失败！`
              );
              console.log("走下载接口");
              that
                .$http({
                  url: "/too/center/wechat/exportDealErrorList",
                  params: {
                    batchNo: res.data.result.batchNo,
                  },
                  method: "get",
                  responseType: "blob",
                  headers: {
                    token: JSON.parse(localStorage.getItem("token")),
                  },
                })
                .then((res) => {
                  console.log("作废失败后再次下载下来的文件", res.data);
                  const blob = new Blob([res.data], {
                    type: "application/vnd.ms-excel",
                    crossOrigin: "Anonymous",
                  });
                  const a = document.createElement("a");
                  let date = new Date();
                  var year = date.getFullYear();
                  var month =
                    date.getMonth() + 1 < 10
                      ? "0" + (date.getMonth() + 1)
                      : date.getMonth() + 1;
                  var day =
                    date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
                  var hours =
                    date.getHours() < 10
                      ? "0" + date.getHours()
                      : date.getHours();
                  var minutes =
                    date.getMinutes() < 10
                      ? "0" + date.getMinutes()
                      : date.getMinutes();
                  var seconds =
                    date.getSeconds() < 10
                      ? "0" + date.getSeconds()
                      : date.getSeconds();
                  // 拼接
                  let ds =
                    year +
                    "-" +
                    month +
                    "-" +
                    day +
                    " " +
                    hours +
                    ":" +
                    minutes +
                    ":" +
                    seconds;
                  document.body.appendChild(a);
                  a.style.display = "none";
                  // 使用获取到的blob对象创建的url
                  const url = window.URL.createObjectURL(blob);
                  a.href = url;
                  // 指定下载的文件名
                  a.download = "兑换码批量作废失败数据" + ds + ".xls";
                  a.click();
                  document.body.removeChild(a);
                  // 移除blob对象的url
                  window.URL.revokeObjectURL(url);
                });
            } else {
              this.$message({
                type: "success",
                message: "已全部作废成功!",
              });
            }
            this.getEquipment();
          } else {
            this.$message.error(res.data.msg);
          }
        });
    },
    btnStatus(row) {
      console.log(row, "变更查询1");
      this.$confirm("该操作不可逆，确定要将此兑换码作废处理吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          apiShout.cancelCode({ id: row.id }).then((res) => {
            console.log(res);
            if (res.data.code === 200) {
              this.$message({
                type: "success",
                message: "作废成功!",
              });
              this.getEquipment();
            } else {
              this.$message.error(res.data.msg);
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
          });
        });
      // this.dialogVisible = true;
    },
    //批量发布
    uploadAll() {
      this.$refs.upload.submit();
      this.allVisible = false;
    },
    //批量作废
    uploadAlls() {
      this.$refs.uploads.submit();
      this.allVisibles = false;
    },
    handleQuery() {
      this.getEquipment();
    },
    getEquipment() {
      apiShout
        .getExchangeCodes({
          pageCount: this.pageCount,
          pageSize: this.pageSize,
          cardNo: this.SerachInput.serialNumber,
          code: this.SerachInput.key,
          status: this.SerachInput.currentState,
          createStartDate:
            this.SerachInput.time == null ? "" : this.SerachInput.time[0],
          createEndDate:
            this.SerachInput.time == null ? "" : this.SerachInput.time[1],
          exchangeStrartTime:
            this.SerachInput.times == null ? "" : this.SerachInput.times[0],
          exchangeEndTime:
            this.SerachInput.times == null ? "" : this.SerachInput.times[1],
          activityId: this.SerachInput.activityName,
          phone: this.SerachInput.phone,
          imei: this.SerachInput.imei,
        })
        .then((res) => {
          if (res.data.code === 200) {
            this.tableData = res.data.result.data;
            this.totalCount = res.data.result.page.totalCount;
          } else {
            this.$message.error(res.data.msg);
          }
        });
    },
    //当前在第几页
    handleCurrentChange(pageCount) {
      this.pageCount = pageCount;
      this.getEquipment();
    },
    //保存
    // preservation() {},
    handleClose(done) {
      done();
    },
    Toview(row) {
      console.log(row, "查询row000");
      this.functionQuery = row;
      this.dialogVisible = true;
    },
  },
};
</script>

<style lang='less' scoped>
/deep/.el-upload__input {
  display: none;
}
/deep/.el-dialog__headerbtn {
  top: 10px;
}
/deep/.el-form-item__label {
  color: #909399;
  text-align: left;
}
/deep/.el-input__inner {
  height: 33px;
  line-height: 33px;
  padding: 0 10px;
}
/deep/.el-form-item {
  margin-bottom: 5px;
  margin-right: 30px;
}
.el-form {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.top {
  height: 200px;
  margin: 20px 40px 20px 25px;
}
.top-btn {
  float: left;
  margin-right: 30px;
  background: #d78673;
  color: #ffffff;
}

.top-search {
  position: relative;
}
.con-size {
  margin: 0px 10px 20px 40px;
}
.top-img {
  margin-left: 10px;
  position: absolute;
  top: 60px;
}
/deep/.el-table::before {
  height: 0px;
}
.table-content {
  padding: 0px 10px 10px 0px;
}
/deep/ .el-table .cell {
  text-align: center;
}
.bottom-right {
  float: right;
}
.buttons {
  display: flex;
  justify-content: flex-end;
  .el-button {
    width: 130px;
    height: 40px;
    line-height: 40px;
    border-radius: 25px;
    margin-top: 20px;
    margin-right: 20px;
    background: #d78673;
    color: #ffffff;
    font-size: 16px;
    padding: 0px;
  }
}
.bottom-b {
  float: right;
  margin-right: 10%;
}
.top-btn1 {
  height: 20px;
  line-height: 0;
  font-size: 16px;
  text-align: center;
  padding: 10px;
  margin-bottom: 20px;
  float: left;
  margin-right: 20px;
  background: #d78673;
  color: #ffffff;
}
.bottom-fen {
  margin-top: 30px;
}
.bottom-left {
  position: relative;
}
.bottom-shu {
  font-size: 15px;
  color: #606266;
}
.healLabel {
  width: 400px;
  height: 50px;
  background-color: #ebe9e9;
  line-height: 50px;
  float: left;
  margin-right: 20px;
  overflow: hidden;
  letter-spacing: 1px;
}
.offen {
  margin-left: 150px;
}
/deep/.el-table td,
.el-table th.is-leaf {
  border-bottom: none !important;
}
/deep/.el-table .cell {
  text-align: center !important;
}
/deep/.el-table thead tr th.is-leaf {
  border: none;
  border-right: none;
}
</style>